import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "un-gîte-entre-nancy-et-metz"
    }}>{`Un `}<strong parentName="h1">{`Gîte`}</strong>{` entre `}<strong parentName="h1">{`Nancy et Metz`}</strong></h1>
    <p>{`Ancien pigeonnier rénové. Idéal pour roucouler en amoureux …
RDC : Cuisine – 1er Etage Salle de bain, WC, Salon avec TV -DVD
2ème Etage Chambre (lit 180×200)..avec T.V. Entièrement climatisé.
Son plus: Echelle de meunier, vue sur le parc.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "527px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eb558c8c9ff7a5a36b5b0e5404908c08/7575c/LesChambresG%C3%AEte2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVQozwEZA+b8AL3IodPb2bK8krCziMPDtNDUsNDVp7Kzh29xTZOTWa+vZ8zNhuHZs8qtt+XR2O/c6Nq/0tK4zdSxw+bO2wCquHrb39PQ1MrCx6q8upG9w469w3u/xXKHgk6Ni0+kpWK6unHM1o7Ht6DIm5/On6XOp7G9pLe3mKzFprsAucBvy86ez9GzuMCOqKxXoaJTnqNTsbhmg4hZfHhKp6JasrFqkZdmiIFohm5jknNloYV0nYaCooKOsY2XALa6d8fMlNPXnrW+c6SvW6GnXJmfUqGlX5OYZnx+WoOFV5WRXn5xXHFfTYFtVpV9YaSIabCTcJd9cZZvdACwtXfEy5GrtH2EklSAkUxcbj2Hjkd4fkZzekhqc0txdkyBc1aRdFuXeFuigF+vi2bAmXHVs5Ghh3qVb24ApKpWpa9ahY9Pa3c4aHU2Q1EpYW4zPEYqMz8nNUApPUswg3dbqI1smX5gk3dZupVrxaB317OOoIZ4gGViAIeWPpGfSpmkV5CcUaKsX5yiYaKoZ1hjMTAxI0NHKU5MMIt9YqqObo13Xox0W8Gdc8uphNGuiaSLeIdoYQBmdy5/jUKZplOJmUSSpE6gq16+xH6XmnFrakuFeVd8bVqUiHWmjW+TdFOvjWfCoHjIp4XQq4aMemp0ZlYAYHEqcYQ0c4E5aHE0fo1Bl6RbkpdfamNbY11KdnFniINplYpxmX9kh25TpIlqv6F/v55/2rWSmYl8amtcAGNjLWBmLktSIVNZJGdyOlNbPj5CNDY3Mj08P1JPTWZfSXhqV4luV3NkU39uW66OaLWTb7eWc7abeoxxXABqVT5VUjczMRVUVytueE1qc212fn5/h4yAhZBcXVE7Pi1NRz5eU0JcUD9eUT+IaUmceFV6YEa3pHC3soAAblQ6WU04RkAxW1M+kIls1M+8+fb0+fn5zs3PlJWWcnZ1ZmlpYWNhX11YWFZMZFtNZFpIg2VSp45xoZd5AKqUfr6ok8KsmLylk72nlsm2o9TEt93RyPPt5/Tz8PDt7Onn5+Xk5N/e39vZ29LR0tHS1KuWi5d7cIx5eIkanK9phw6GAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/eb558c8c9ff7a5a36b5b0e5404908c08/f994e/LesChambresG%C3%AEte2.webp 163w", "/static/eb558c8c9ff7a5a36b5b0e5404908c08/bcd70/LesChambresG%C3%AEte2.webp 325w", "/static/eb558c8c9ff7a5a36b5b0e5404908c08/a3ab3/LesChambresG%C3%AEte2.webp 527w"],
              "sizes": "(max-width: 527px) 100vw, 527px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/eb558c8c9ff7a5a36b5b0e5404908c08/cf3f8/LesChambresG%C3%AEte2.png 163w", "/static/eb558c8c9ff7a5a36b5b0e5404908c08/bb21a/LesChambresG%C3%AEte2.png 325w", "/static/eb558c8c9ff7a5a36b5b0e5404908c08/7575c/LesChambresG%C3%AEte2.png 527w"],
              "sizes": "(max-width: 527px) 100vw, 527px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/eb558c8c9ff7a5a36b5b0e5404908c08/7575c/LesChambresG%C3%AEte2.png",
              "alt": "Gite de France Nancy",
              "title": "Gite de France Nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "527px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eee672ac24cbf16e021fac7ab6578ca1/7575c/LesChambresG%C3%AEte1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVQozwEZA+b8AGVhXWllYG1pZXd1dndzcnFqZXBrZXJsZXV6dn5FQ7ZLRsqwm8afiJd7YLGUfteglu7e19mWg/DEpv/mxABvbWpybWp3dXJ9c3V9d3h3c29zbml6d3SFkJCJU1S0S0bMsqDIoY6Ze2Czkn3dmZH55eLYhXHyw6H/4sAAdnV0eXRzhISIenV2hYSHhYOFfXl3g39/kZ2hkWBjrUZAx66dxJ6Jz6mXyqKUzpCI6L24ynBa5LGT8MiqAHNycX5+g4WGjWxnZX17fYyPl3x3doODg4qChYxPVZ06NMGhi3lcUC8kH6J/b8uons+qn8qZi9KnjtavlABpaGh9fH+Dc2xvZGJwXViNiY2AgYhvXVyGdXuNXWOSNymmel5QKx0eBQB5Uzy4loOzeFXChVnBjGPCmXYAaVJVopukpZ2hp6Goo5qho5ugpKCqk4iNjYWNkGtvnzMpr2BQqnBcol1KoEExq1JApUoqrFsynEonnEYvAHxfZIJpb4lydoxzd4ZwcoBoaHlhYIVucYuDhoJmZ3gaGIpHQYdCOHgbFn4PDIwjG5RGOJFPPn0jHW4FBAB8WUGNZkiytLmXioeYh4WXhoSXg3+cg3+agHWkgWysgmSvjW6qhmijgmehh2+ginWjjn6kjn+Mc2B+Z04AiWhXjE4iq4VxzszMtauqpZOUura5saSfmlZPo1VBza6FwqB5u5t3t5l5qpJ20cu++Pn35uLcmohuhm9RAI12cpV5colDF9TCubanop57d7+9xbCtr5VERpc9LMaogLqZdZaFd5+Id5p8YsvAsu3r5cu8rX1YO29NMABMPC+OdHKGXEeaY0Gff3WXdXLHzd/FyteUREqPNye4n3mylHewrKqbkomJaEaTeFacfmGYf2aNeGN3X0gATzcjWkEujHl5hVExhk4vk3d+xdDqyNbqkj1HiCofrJV2pYxyqaKhk3xyeTwUekIgbDYOb1AycXR0c3BqAG1AKFk1HnJNOYNVR4Y/HJNvari908TS6J0yQIMdFIdmRZpcQalgSqpfSqVoTaFlU55hUIliUVxTS2VfWa+RhxkKVeQ/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/eee672ac24cbf16e021fac7ab6578ca1/f994e/LesChambresG%C3%AEte1.webp 163w", "/static/eee672ac24cbf16e021fac7ab6578ca1/bcd70/LesChambresG%C3%AEte1.webp 325w", "/static/eee672ac24cbf16e021fac7ab6578ca1/a3ab3/LesChambresG%C3%AEte1.webp 527w"],
              "sizes": "(max-width: 527px) 100vw, 527px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/eee672ac24cbf16e021fac7ab6578ca1/cf3f8/LesChambresG%C3%AEte1.png 163w", "/static/eee672ac24cbf16e021fac7ab6578ca1/bb21a/LesChambresG%C3%AEte1.png 325w", "/static/eee672ac24cbf16e021fac7ab6578ca1/7575c/LesChambresG%C3%AEte1.png 527w"],
              "sizes": "(max-width: 527px) 100vw, 527px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/eee672ac24cbf16e021fac7ab6578ca1/7575c/LesChambresG%C3%AEte1.png",
              "alt": "Gite de France Metz",
              "title": "Gite de France Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Gite disponible à partir de 17 heures.
Minimum 2 nuits`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      